import React from 'react';
import {graphql, Link} from 'gatsby';
import useDarkMode from 'use-dark-mode';

// Components
import Layout from '../components/layout';
import SEO from '../components/seo';

const PostTemplate = (props) => {
  let initDarkMode = false;
  if (typeof window !== `undefined`) {
    initDarkMode = JSON.parse(localStorage.getItem('darkMode'))
  }
  const darkMode = useDarkMode(initDarkMode);

  const frontmatter = props.data.markdownRemark.frontmatter;
  const {title, subtitle, description, date} = frontmatter;
  const post = props.data.markdownRemark;
  const {previous, next, slug, websiteTitle} = props.pageContext;

  return (
    <Layout title={websiteTitle} subtitle={title}>
      <SEO title={title} description={description || post.excerpt} slug={slug} />
      <section className={darkMode.value ? 'posts-dark' : 'posts'}>
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        <h4 className={darkMode.value ? 'date-dark' : 'date'}>{date}</h4>
        <div dangerouslySetInnerHTML={{__html: post.html}} />
        <ul>
          <li className='post-navigation'>
            {previous && (
              <Link to={previous.fields.slug} rel='prev'>
                ⬅ {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li className='post-navigation'>
            {next && (
              <Link to={next.fields.slug} rel='next'>
                {next.frontmatter.title} ➡
              </Link>
            )}
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query Posts($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        subtitle
        description
        author
      }
    }
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
